<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">
            <form class="theme-form">
              <img style="padding-left: 40px;width: 100%;" src="../../assets/big_logo.png" alt="">
              <h4>Реєстрація</h4>
              <div class="form-group">
                <input class="form-control" autocomplete="false" type="text" required="" placeholder="Назва компанії" formControlName="login" />
              </div>
              <div class="form-group">
                <input class="form-control" autocomplete="false" type="text" required="" placeholder="Email" formControlName="login" />
              </div>
              <div class="form-group">
                <input class="form-control" autocomplete="false" type="text" required="" placeholder="+38 (ХХХ) ХХХ-ХХ-ХХ" formControlName="login" />
              </div>
              
              <div class="form-group">
                <input class="form-control"  autocomplete="false" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="Пароль" />
              </div>
              <div class="form-group mb-0">
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Passwort vergessen?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100"type="button" [routerLink]="['/orders']"><span>Зареєструватися</span></button>
                </div>
              </div>
              <div class="reg"><span>Вже зареєстровані?</span> <a [routerLink]="['/auth/login']">Увійти</a></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
