<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">
            <form class="theme-form">
              <img style="padding-left: 40px;width: 100%;" src="../../assets/big_logo.png" alt="">
              <h4>Вхід</h4>
              <div class="form-group">
                <input class="form-control" autocomplete="false" type="text" placeholder="Email" [(ngModel)]="email" />
              </div>
              <div class="form-group">
                <input class="form-control" type="password"  autocomplete="false" [(ngModel)]="password" placeholder="Пароль" />
              </div>
              <a href="" class="forgot" [routerLink]="['/auth/forgot']">Забули пароль?</a>
              <div class="form-group mb-0">
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Passwort vergessen?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" type="button" (click)="login()"><span>Вхід</span></button>
                </div>
              </div>
              <div class="reg"><span>Немає акаунту?</span> <a [routerLink]="['/auth/registration']">Зареєструватися</a></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
