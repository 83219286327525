import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    public url = 'https://api.bioetanol20skald.com.ua/client/';
    //public url = 'http://localhost:3001/';

    errors = {
        'ERROR':'Помилка'
    }

    constructor(private http: HttpClient) { }

    validateEmail(email: string) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    getStatus(status){
        let statusStr = '';

        switch(Number(status)){
            case 0: statusStr = 'Новий'; break;
            case 1: statusStr = 'Виконано'; break;
            case 2: statusStr = 'Відхилено'; break;
            case 9: statusStr = 'Чернетка'; break;
            default: statusStr = '--'; break;
        }

        return statusStr;
    }

    get(path: string) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['AUTH'] = localStorage.getItem('token');
            }

            this.http.get(url, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    post(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['AUTH'] = localStorage.getItem('token');
            }

            this.http.post(url, data, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    patch(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['AUTH'] = localStorage.getItem('token');
            }

            this.http.patch(url, data, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    delete(path) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['AUTH'] = localStorage.getItem('token');
            }

            this.http.delete(url, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }
}